// STRAPI

setTimeout(strapi.fetchGufInfo, 200);
setTimeout(strapi.fetchGufInfo, 3000);
setInterval(strapi.fetchGufInfo, 15000);

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const debug = urlParams.get('debug') === 'true' || false;
const debugDonationFrequency = parseInt(urlParams.get('debugDonationFrequency') || 1000);
const debugDonationMaxXSpawn = parseInt(urlParams.get('debugDonationMaxXSpawn') || 100);
window.debugDonationMaxXSpawn = debugDonationMaxXSpawn;

const stopLoop = urlParams.get('stopLoop') === 'true' || false;
console.log("Looping is", !stopLoop ? "enabled" : "disabled");
window.stopLoop = stopLoop;

const showControls = urlParams.get('showControls') === 'true' || false;
window.showControls = showControls;

if (showControls) {
    document.getElementById("debug-controls").classList.remove("hide");
}

if (!stopLoop)
    strapi.callWhenReady(infoBar.start);

// OENGUS
setInterval(function() {
    oengus.updateCurrentTime();
}, 3000);

strapi.callWhenReady(oengus.refreshSchedule);
strapi.callWhenUpdated(oengus.showSchedulePreview);
strapi.callWhenUpdated(oengus.refreshScheduleIfOffsetChanged);
strapi.callWhenUpdated(oengus.updateOffsetInputs);
strapi.callWhenUpdated(strapi.parseIncentives);